import { LiFiWidget, WidgetConfig } from '@lifi/widget';

import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

const widgetConfig: WidgetConfig = {
  // It can be either default, expandable, or drawer
  variant: 'expandable',
  subvariant: 'split', // It can be either split or full
  containerStyle: {
    border: `1px solid rgb(22, 22, 22)`,
  },
  theme: {
    palette: {
      primary: { main: '#fc55ba' },
      secondary: { main: '#6fcdf6' },
      background: { 
        paper: '#131313', // bg color for cards
        default: '#131313', // bg color container
        },
      grey: {
        300: '#000000', // border light theme
        800: '#000000', // border dark theme
        },
    },
    shape: {
      borderRadius: 0,
      borderRadiusSecondary: 0,
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
    
  },
  appearance: 'dark',
  hiddenUI: ['appearance'],
  fee: 0,
  integrator: 'xchainag',
};

function App() {
  return (
    <>
      <div className="container mt-3 text-center">
        <img src={process.env.PUBLIC_URL + '/logo_xchain.jpg'} alt="logo" className="App-logo" />
        <h2><strong>xchain.ag</strong></h2>
        <p><strong>Crosschain Bridging and Swaps: Simple, Fast, Cheap.</strong></p>
      </div>
      <div className="container mt-4">
        <LiFiWidget
          config={widgetConfig}
          integrator="xchain"
        />
      </div>
      <div className="container text-center">
        <a href="https://xchain-1.gitbook.io/xchainag/" target="_blank" rel="noopener noreferrer" className="custom-link">Docs</a>
        <a href="https://xchain-1.gitbook.io/xchainag/api/quickstart" target="_blank" rel="noopener noreferrer" className="custom-link">API</a>
        <a href="https://xchain-1.gitbook.io/xchainag/airdrop" target="_blank" rel="noopener noreferrer" className="custom-link">Airdrop</a>   
        <a href="https://xchain-1.gitbook.io/xchainag/about" target="_blank" rel="noopener noreferrer" className="custom-link">About</a> 
      </div>
    </>
  );
}

export default App;
